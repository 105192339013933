import React, {Component, KeyboardEvent} from "react"
import styles from "./SignIn.module.css"
import {Button} from "./parts/Button"
import {Label} from "components/inputs/Label"
import {Input} from "components/inputs/input"
import {Captcha} from "components/Captcha"
import {LoginWrapper} from "components/LoginWrapper"
import {Link} from "react-router-dom"
import {Model} from "../../Model"
import {Authorisation} from "models/Authorisation"
import {observable} from "mobx"
import {observer} from "mobx-react"
import {ReactComponent as ClockIcon} from "./parts/img/icons/clock.svg"
// import {ReactComponent as CalendarIcon} from "./parts/img/icons/calendar.svg"
import {findErrors} from "../../lib/functions/findErrors"

interface Props {
    model: Model
    auth: Authorisation
}

const mandatory = {
    presence: {allowEmpty: false}
}

const rules = {
    email: {
        email: true
    },
    password: {
        ...mandatory,
        length: {minimum: 3}
    },
    captcha_value: mandatory
}

@observer
export class SignIn extends Component<Props, {}> {
    @observable
    private errors: {[key: string]: string} = {}

    @observable
    private backendErrors: string | null = ""

    @observable
    private user = {
        email: "",
        password: "",
        captcha_value: ""
    }

    private KEY_CODE = 13
    private KEY = "Enter"

    private recaptchaRef: any = React.createRef()

    private isValid = () => {
        const {isValid, errors} = findErrors(this.user, rules)
        this.errors = errors
        return isValid
    }
    private onChangeCaptchaText = (id: string, value: string) => {
        this.user[id] = value
    }

    private onKeyDownSignIn = (event: KeyboardEvent<Element>) => {
        // event.preventDefault()
        const {which, keyCode, key} = event
        if (which === this.KEY_CODE || keyCode === this.KEY_CODE || key === this.KEY) {
            this.signIn()
        }
    }

    public render() {
        const {onKeyDownSignIn, onChangeCaptchaText, backendErrors} = this
        // const {model} = this.props
        return (
            <LoginWrapper>
                <div className={styles.root}>
                    <h2 className={styles.h2}>Welcome!</h2>
                    <p className={styles.text}>
                        Thank you for your interest in applying to <br /> Salem University.
                    </p>
                    <article className={styles.article}>
                        <div className={styles.articleTitleWrapper}>
                            <h2 className={styles.articleTitle}>FIND YOUR PURPOSE!</h2>
                        </div>
                        <ul className={styles.articleList}>
                            <li className={styles.articleItem}>
                                <div className={styles.articleIconS}>$</div>
                                <p className={styles.articleText}>
                                    <b>Salem University</b>. Provide an educational experience like
                                    no other.
                                </p>
                            </li>
                            <li className={styles.articleItem}>
                                <ClockIcon className={styles.articleIcon} width="11" height="11" />
                                <p className={styles.articleText}>
                                    Our enrollment team will review your transcripts and provide a
                                    comprehensive graduation plan including estimated costs, options
                                    to start your program and your estimated graduation date.
                                </p>
                            </li>
                        </ul>
                    </article>
                    {/* <Button
                        text="NEW ENGLAND CAMPUS DISCLOSURE"
                        className={styles.buttonDsc}
                        onClick={() => {
                            window.open(
                                `${process.env.REACT_APP_DISCLOSURE}`,
                                "_blank",
                                "noopener,noreferrer"
                            )
                        }}
                    /> */}
                    <Button
                        text="CREATE A NEW ACCOUNT"
                        className={`${styles.button} ${styles.buttonGreen}`}
                        onClick={() => {
                            this.props.model.getHistory().push("/sign-up")
                        }}
                    />

                    <article className={styles.loginArea}>
                        <h3 className={styles.h3}>LOGIN WITH AN EXISTING ACCOUNT</h3>
                        <div className={styles.inner}>
                            <p className={`${styles.text} ${styles.textForm}`}>
                                If you have already started or submitted an application, sign in
                                here to access your information.
                            </p>
                            <Label
                                text="Email address"
                                className={styles.inputUser}
                                error={this.errors["email"]}
                                required>
                                <Input
                                    onChange={(v) => {
                                        this.user.email = v
                                    }}
                                    value={this.user.email}
                                    onKeyDown={onKeyDownSignIn}
                                />
                            </Label>

                            <Label
                                text="Password"
                                required
                                className={styles.inputPass}
                                error={this.errors["password"]}>
                                <Input
                                    onChange={(v) => {
                                        this.user.password = v
                                    }}
                                    value={this.user.password}
                                    type="password"
                                    onKeyDown={onKeyDownSignIn}
                                />
                            </Label>
                            <Captcha
                                recaptchaRef={this.recaptchaRef}
                                id={`captcha_value`}
                                user={this.user}
                                onChange={onChangeCaptchaText}
                                onKeyDown={onKeyDownSignIn}
                                errors={this.errors}
                                backendErrors={backendErrors}
                            />
                            <Button
                                text="SIGN IN"
                                onClick={this.signIn.bind(this)}
                                className={styles.loginButton}
                            />

                            <Link to="/accounts/recovery" className={styles.link}>
                                I forgot my password
                            </Link>

                            <Link to="/sign-up" className={styles.link}>
                                I do not have an account
                            </Link>
                        </div>
                    </article>
                </div>
            </LoginWrapper>
        )
    }

    private async signIn() {
        this.backendErrors = ""
        const CAPTCHA_WORD = "Captcha"
        if (this.recaptchaRef.current) {
            const captchaToken = await this.recaptchaRef.current.executeAsync()
            console.log("@@@ signIn captch_value", captchaToken)
            this.user.captcha_value = captchaToken
            this.recaptchaRef.current.reset()
        }
        if (this.isValid()) {
            const user = this.user
            const response = await this.props.auth.signIn("salemu", {...user, skip: false})
            // if (this.recaptchaRef.current) {
            //     this.recaptchaRef.current.reset()
            //     this.user.captcha_value = ""
            // }
            if (!response.success) {
                const {
                    errors: [{message}]
                } = response
                if (message.includes(CAPTCHA_WORD)) {
                    this.backendErrors = message
                }
            }
        }
    }
}
